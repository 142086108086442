import axiosInstance from "../axiosConfig";
import { API_ENDPOINTS } from "../apiEndpoints";

export const signIn = async (params) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.LOGIN(), {
      ...params,
    });
    if (response.status == 200) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem(
        "token",
        JSON.stringify(response.data.data?.accessToken)
      );
    }
    return response;
  } catch (error) {
    throw error;
  }
};


