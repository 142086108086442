import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateUser } from "api/services/userService";
import { useAuth } from "contexts/authContext";
import { confirmAlert } from "react-confirm-alert";
import CustomModal from "components/CustomModal/CustomModal";
import toast from "react-hot-toast";

const Settings = () => {
  const { user, logout } = useAuth();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Required"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });
  const showModal = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomModal
          title="Change Password"
          body="Are you sure you want to change password ?"
          buttons={[
            {
              color: "danger",
              onClick: onClose,
              text: "Cancel",
            },
            {
              color: "info",
              onClick: () => {
                changePassword(data);
                onClose();
              },
              text: "Save",
            },
          ]}
        />
      ),
      overlayClassName: "modal-overlay",
    });
  };
  const changePassword = async (values) => {
    try {
      const response = await updateUser(user._id, {
        ...values,
        action: "changePassword",
      });
      if (response.status != 200) return toast.error(response.response.data);
      toast.success("Password Changed Successfully");
      toast.loading("Loging out! ,Please login again");
      setTimeout(() => {
        logout();
      }, 5000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="content">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h5 className="title">Change Password</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={showModal}
                  >
                    <FormikForm>
                      <FormGroup>
                        <label>Old Password</label>
                        <Field
                          placeholder="Old Password"
                          type="password"
                          name="oldPassword"
                          id="oldPassword"
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"span"}
                          name="oldPassword"
                          className="input-error"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>New Password</label>
                        <Field
                          placeholder="New Password"
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"span"}
                          name="newPassword"
                          className="input-error"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Confirm New Password</label>
                        <Field
                          placeholder="Confirm New Password"
                          type="password"
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"span"}
                          name="confirmNewPassword"
                          className="input-error"
                        />
                      </FormGroup>
                      <Button className="btn-fill w-100" color="info">
                        Change Password
                      </Button>
                    </FormikForm>
                  </Formik>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
