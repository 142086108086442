import React, { useEffect, useState } from "react";
import dashbaord_descr_img from "../assets/images/dashboard_description.jpg";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import "../assets/css/newDashboard.css";
const NewDashboard = () => {
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"];

  const data = {
    labels: labels, // Ensure labels are properly defined
    datasets: [
      {
        label: "Messages",
        data: [65, 59, 80, 81, 56, 55, 40, 89],
        fill: false,
        //   backgroundColor: 'rgba(75,192,192,0)',
        borderColor: "rgba(75,192,192,.85)",
      },
     
    ],
    
  };

  const trafficData = {
    labels: labels, // Ensure labels are properly defined
    datasets: [
        {
            label: "Inbound",
            data: [65, 59, 80, 81, 56, 55, 40, 89],
            fill: false,
            //   backgroundColor: 'rgba(75,192,192,0)',
            borderColor: "rgb(191, 46, 240,.85)",
          },
          {
            label: "Outbound",
            data: [12, 88, 22, 11, 33, 22, 49, 34],
            fill: false,
            //   backgroundColor: 'rgba(75,192,192,0)',
            borderColor: "rgba(10,192,192,.85)",
          },
    ],
  }

  //  useEffect(()=>{

  //     setChartData( )
  //  },[labels])

  return (
    <div className="content">
      <h1>Dashboard</h1>
      {/* Description */}
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="description-container">
              <div className="text">
                <h4 className="card-title">Welcome to Perfect Connects</h4>
                <p>
                  Perfect Connects is a messaging platform that allows you to
                  connect with your customers globally through our seamless
                  WhatsApp messaging service. Easily register, send, and receive
                  messages worldwide, all from one intuitive platform. Stay
                  connected, anytime, anywhere.
                </p>
              </div>
              <div className="image">
                <img src={dashbaord_descr_img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Description ends here */}
      {/* Graph */}
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Messages</h4>
              <div>
                {data && (
                  <Line
                    data={data}
                    options={{ responsive: true, maintainAspectRatio: false }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Inbound,Outbound Traffic</h4>
              <div>
                {trafficData && (
                    <Line
                        data={trafficData}
                        options={{ responsive: true, maintainAspectRatio: false }}
                    />
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Graph end here */}

      {/* Table */}
      <div className="row">
        <div className="col-md-12">
        <h4 className="card-title">Message Log</h4>
        <Table >
          <thead>
            <tr>
              <th>Date</th>
              <th>Service</th>
              <th>Type</th>
              <th>From</th>
              <th>To</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>2022-01-01</td>
              <td>Service A</td>
              <td>Inbound</td>
              <td>123567892</td>
              <td>128788773</td>
              <td>Delivered</td>
            </tr>
            <tr>
              <td>2022-01-02</td>
              <td>Service B</td>
              <td>Outbound</td>
              <td>123567892</td>
              <td>123829833</td>
              <td>Read</td>
            </tr>
            <tr>
              <td>2022-01-03</td>
              <td>Service C</td>
              <td>Inbound</td>
              <td>123567892</td>
              <td>123567823</td>
              <td>Pending</td>
            </tr>
          </tbody>
        </Table>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
