import axios from "axios";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    const data = error.response?.data;
    console.log(data?.error);
    if (data?.error?.name === "TokenExpiredError") {
      toast.error("Session expired, Please sign in");
      localStorage.removeItem("user");
      window.location = "/signin";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
