import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Container,
  CardImg,
} from "reactstrap";

import { ReactComponent as Logo } from "logo.svg";
import { useAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, Field, ErrorMessage  } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import login_bg from "assets/img/login_bg.jpg";
import { signUp } from "../api/services/userService";

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showSignupForm,setShowSignupForm] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordIcon, setPasswordIcon] = useState("fa-eye-slash");
  
  // navigate("/signin");
  const handleSubmit = async (data,{ setSubmitting }) => {
    console.log(data);
    setSubmitting(false);
    
    setLoading(true);
    try {

      data.registrationType = "self"
      data.type = "user"
      const response = await signUp(data);
      if(response.status == 201){
        // toast.success("Signup successful! Welcome aboard.");
        setShowSignupForm(false);
        setShowResponseMessage(true);
        setResponseMessage("Your signup was a success! Check your inbox for a verification email and get ready to dive in.");
        // navigate("/signin");
        setLoading(false);
      }else{
        console.log(response?.response.data.message);
        toast.error(response?.response.data?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handlePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setPasswordIcon("fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIcon("fa-eye-slash");
    }
  };
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
  });

  // "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"

  return (
    <Container className="vh-100 d-flex align-items-center">
      <Card className="card-custom">
        <Row>
          <Col md="5" className="d-none d-md-block">
            <img
              src={login_bg}
              alt="login form"
              className="rounded-start h-100"
            />
          </Col>
          <Col md="7">
            <CardBody className="d-flex flex-column justify-content-center align-items-center h-100 px-4">
              <div style={{ width: "450px", maxWidth: "100%" }}>
                <div
                  className="d-flex flex-column mt-2"
                  style={{ gap: "15px" }}
                >
                  <Logo />
                  <h1 className="mb-0 text-white">Perfect Connects</h1>
                </div>
                {showSignupForm && (
                <div className="signup-form-wrapper">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, isValid, errors }) => (

                  
                   
                  <FormikForm>
                    <h5 className="mt-2 mb-1">Signup </h5>
                    <div className="pb-2">
                      Just fill out the form below to get started!
                     test {Object.keys(errors).length}
                    </div>
                    <FormGroup>
                      <label>Full Name</label>
                      <Field
                        placeholder="Full Name"
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                      />
                      <ErrorMessage
                        component={"span"}
                        name="name"
                        className="input-error"
                      />
                    </FormGroup>
          
                    <FormGroup>
                      <label>Email</label>
                      <Field
                        placeholder="Email"
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        component={"span"}
                        name="email"
                        className="input-error"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Password</label>
                      <div className="d-flex justify-content-between">
                        <Field
                          placeholder="Password"
                          type={passwordType}
                          id="password"
                          className="form-control"
                          name="password"
                        />
                        <span
                          className="position-absolute"
                          style={{ right: "13px", top: " 34px" }}
                        >
                          <i
                            role="button"
                            className={`fa-solid ${passwordIcon}`}
                            onClick={handlePasswordType}
                          ></i>
                        </span>
                      </div>

                      <ErrorMessage
                        component={"span"}
                        name="password"
                        className="input-error"
                      />
                    </FormGroup>
                    <Button
                      className="btn-fill w-100"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || (!isValid && Object.keys(errors).length > 0)}
                    >
                      Submit
                
                    </Button>
                  </FormikForm>
                  )}
                </Formik>
                <div className="d-flex flex-row justify-content-start">
                  <a
                    href="/legal/terms_of_service_and_privacy_policy.html"
                    className="small text-muted me-1"
                  >
                    Privacy policy and Terms of Service.
                  </a>
                </div>
                <div className="text-center mt-5">
                  <h5>
                    Existing user?{" "}
                    <a href="/signin">
                      <b>Signin</b>
                    </a>{" "}
                    here
                  </h5>

                  <a href="https://perfectconnects.in">
                    <h5>
                      Go to <b>Home</b> page
                    </h5>
                  </a>
                </div>
                </div>
                )}
                {showResponseMessage && (
                  <div className="text-left py-4">
                    <h4>Welcome to Perfect Connects!</h4>
                    <div className="text-white pb-2">{responseMessage}</div>
                    <Button
                      className="btn-fill w-100"
                      color="primary"
                      onClick={() => navigate('/signin')}
                    >
                      Signin
                    </Button>
                  </div>
                )}
              </div>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Signup;
