import { getCustomer } from "api/services/customerService";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import { updateCustomer } from "api/services/customerService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast from "react-hot-toast";
import CustomModal from "components/CustomModal/CustomModal";

const CustomerActions = () => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [customer, setCustomer] = useState({});
  const navigate = useNavigate();
  const fetchCustomerDetails = async () => {
    try {
      const response = await getCustomer(id);
      setCustomer(response.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    fetchCustomerDetails();
  }, []);
  const handleUpdateProfile = async (values) => {
    try {
      const response = await updateCustomer(id, values);
      toast.success("Profile updated");
      navigate("../");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleActiveDeactive = async (state) => {
    try {
      const response = await updateCustomer(id, { isDeleted: state });
      toast.success(response.data.data.message);
      navigate("../");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleSubmit = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomModal
          title="Save Changes"
          body="Are you sure you want to save?"
          buttons={[
            {
              color: "danger",
              onClick: onClose,
              text: "Cancel",
            },
            {
              color: "info",
              onClick: () => {
                handleUpdateProfile(data);
                onClose();
              },
              text: "Save",
            },
          ]}
        />
        // <div className="custom-modal">
        //   <div className="custom-modal-header">
        //     <p>Save changes</p>
        //   </div>
        //   <div className="custom-modal-body">
        //     <p className="">Are you sure you want to save?</p>
        //   </div>
        //   <div className="custom-modal-footer">
        //     <Button
        //       className="btn-fill"
        //       color="danger"
        //       type="submit"
        //       onClick={onClose}
        //     >
        //       Cancel
        //     </Button>
        //     <Button
        //       className="btn-fill"
        //       color="info"
        //       type="submit"
        //       onClick={() => {
        //         handleUpdateProfile(data);
        //         onClose();
        //       }}
        //     >
        //       Save
        //     </Button>
        //   </div>
        // </div>
      ),
      overlayClassName: "modal-overlay",
    });
  };
  const showModalActiveDeactivate = (value) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomModal
          title={
            customer.isDeleted ? "Activate Customer" : "Deactivate Customer"
          }
          body={`Are you sure you want to ${
            customer.isDeleted ? "active" : "deactivate"
          } customer ?`}
          buttons={[
            {
              color: "danger",
              onClick: onClose,
              text: "Cancel",
            },
            {
              color: "info",
              onClick: () => {
                handleActiveDeactive(value);
                onClose();
              },
              text: "Save",
            },
          ]}
        />
      ),
      overlayClassName: "modal-overlay",
    });
  };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5 className="title">Customer Profile</h5>
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  className="btn-icon"
                  color="link"
                  data-toggle="dropdown"
                  type="button"
                >
                  <i className="tim-icons icon-settings-gear-63" />
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                  <DropdownItem onClick={() => setEditMode(true)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      showModalActiveDeactivate(
                        customer.isDeleted ? false : true
                      )
                    }
                  >
                    {customer.isDeleted ? "Activate" : "Deactivate"}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </CardHeader>
            <CardBody>
              {!editMode ? (
                <>
                  <Row className="mb-2">
                    <Col>
                      <p className="title">Name</p>
                      <h5>{customer.name}</h5>
                    </Col>
                    <Col>
                      <p className="title">Email</p>
                      <h5>{customer.email}</h5>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <p className="title">Wh.app Number</p>
                      <h5>{customer.whatsAppNo}</h5>
                    </Col>
                    <Col>
                      <p className="title">Email</p>
                      <h5>{customer.email}</h5>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <p className="title">Wh.app Number</p>
                      <h5>{customer.whatsAppNo}</h5>
                    </Col>
                    <Col>
                      <p className="title">Address</p>
                      <h5>{customer.address}</h5>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <p className="title">Contact Person's Name</p>
                      <h5>{customer.contactPersonName}</h5>
                    </Col>
                    <Col>
                      <p className="title">Contact Person's Number</p>
                      <h5>{customer.contactPersonNumber}</h5>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Formik
                    initialValues={customer}
                    // validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <FormikForm>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label>Bank</label>
                            <Field
                              placeholder="Username"
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"span"}
                              name="name"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <FormGroup>
                            <label>Email</label>
                            <Field
                              placeholder="Email"
                              type="eamil"
                              name="email"
                              id="email"
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"span"}
                              name="email"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label>Wh.app Number</label>
                            <Field
                              placeholder="Wh.app Number"
                              type="number"
                              name="whatsAppNo"
                              id="whatsAppNo"
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"span"}
                              name="whatsAppNo"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-md-1">
                          <FormGroup>
                            <label>Address</label>
                            <Field
                              placeholder="Address"
                              type="text"
                              name="address"
                              id="address"
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"span"}
                              name="address"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label>Contact Person's Name</label>
                            <Field
                              placeholder="Contact Person's Name"
                              type="text"
                              name="contactPersonName"
                              id="contactPersonName"
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"span"}
                              name="contactPersonName"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <FormGroup>
                            <label>Contact Person's Number</label>
                            <Field
                              placeholder="Contact Person's Number"
                              type="text"
                              name="contactPersonNumber"
                              id="contactPersonNumber"
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"span"}
                              name="contactPersonNumber"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-fill"
                          color="primary"
                          type="button"
                          onClick={() => setEditMode(false)}
                        >
                          Discard
                        </Button>
                        <Button
                          className="btn-fill"
                          color="primary"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </FormikForm>
                  </Formik>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerActions;
