import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table ,Button} from "reactstrap";
import "../assets/css/senders.css"

const Senders = () => {
  const navigate = useNavigate()
  return (
    <div className="content">
      <h1>Senders</h1>
      <div className="descr card">
        <div className="card-body">
          <p>
            To use a number with WhatsApp, it must be registered as a WhatsApp
            sender.
          </p>
          <p>
            Additionally, to initiate chats with WhatsApp users, you'll need to
            use message templates that have been approved by WhatsApp.
          </p>
        </div>
      </div>
      {/* Description ends here */}
      <div className="row">
        <div className="col-md-6">
            <div className="card">
        <div className="card-body">
          <h5 className="card-title font-weight-normal">Whatsapp Business Account ID</h5>
          <p className="card-text">
          IJKJJJ8373849349JJJJJ
          </p>
        </div>
        </div>
        </div>
        <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title font-weight-normal">Meta Business Manager ID</h5>
          <p className="card-text">
            IJKJJJ8373849349JJJJJ 
          </p>
        </div>
    </div>
    </div>
    </div>
    {/* Business ID details ends here */}
    <div className="text-right mb-2">
      <span>
        <Button className="btn-fill w-20"
                      color="primary" onClick={()=>{
                        navigate('/create-sender')
                      }}>Create Sender</Button>
      </span>
    </div>
    <div className="card">
      <div className="card-body">
      <Table responsive>
    <thead>
      <tr>
        <th>Whatsapp Number</th>
        <th>Business Display name</th>
        <th>Status</th>
        <th>Quality Rating</th>
        <th>Message Limit</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>+234 123 456 7890</td>
        <td>Business Name</td>
        <td>Active</td>
        <td>High</td>
        <td>500</td>
      </tr>
      <tr>
        <td>+234 123 456 7890</td>
        <td>Business Name</td>
        <td>Active</td>
        <td>High</td>
        <td>500</td>
      </tr>
      <tr>
        <td>+234 123 456 7890</td>
        <td>Business Name</td>
        <td>Active</td>
        <td>High</td>
        <td>500</td>
      </tr>
      <tr>
        <td>+234 123 456 7890</td>
        <td>Business Name</td>
        <td>Active</td>
        <td>High</td>
        <td>500</td>
      </tr>
    </tbody>
      </Table>
      </div>
    </div>
    </div>
  );
};
export default Senders;
