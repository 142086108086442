import React from "react";
import { Button } from "reactstrap";

const CustomModal = (props) => {
  return (
    <div className="custom-modal">
      <div className="custom-modal-header">
        <p>{props.title}</p>
      </div>
      <div className="custom-modal-body">
        <p className="">{props.body}</p>
      </div>
      <div className="custom-modal-footer">
        {props.buttons.map((props, index) => (
          <Button
            color={props.color}
            onClick={() => props.onClick()}
            key={index}
          >
            {props.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default CustomModal;
