import Layout from "layout/Layout";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { checkAuthLoader } from "utils/auth";
import Broadcast from "views/Broadcast";
import CustomerActions from "views/CustomerActions";
import Customers from "views/Customers";
import Dashboard from "views/Dashboard.js";
import EmailVerification from "views/emailVerification";
import Icons from "views/Icons";
import NewDashbaord from "views/NewDashboard";
import Requests from "views/Requests";
import Senders from "views/Senders";
import Settings from "views/Settings";
import Signin from "views/Signin";
import Signup from "views/Signup";
import Tables from "views/TableList";
import UserProfile from "views/UserProfile";
import CreateSender from "views/CreateSender";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/dashboard"} replace />,
  },
  {
    path: "/",
    element: <Layout />,
    loader: checkAuthLoader,
    children: [
      {
        path: "dashboard",
        element: <NewDashbaord />,
      },
      {
        path: "senders",
        element: <Senders />,
      },
      {
        path:"create-sender",
        element:<CreateSender/>
      },
      {
        path: "requests",
        element: <Requests />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "customers",
        children: [
          {
            index: true,
            element: <Customers />,
          },
          {
            path: "actions/:id",
            element: <CustomerActions />,
          },
        ],
      },
      {
        path: "broadcast",
        element: <Broadcast />,
      },
    ],
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/verify-email",
    element: <EmailVerification />,
  }
]);
export default routes;
