import { getTemplates } from "api/services/customerService";
import { useAuth } from "contexts/authContext";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
  Form,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { confirmAlert } from "react-confirm-alert";
import CustomModal from "components/CustomModal/CustomModal";
import { sendBroadcastMessage } from "api/services/customerService";
import { useNavigate } from "react-router-dom";

const Broadcast = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [disableAcType, setdisableAcType] = useState(false);
  const { user } = useAuth();
  const initialValues = {
    customerVia: "",
    acType: "",
    template: "",
    file: null,
  };
  const validationSchema = Yup.object({
    customerVia: Yup.string().required("Required"),
    acType: Yup.string().when("customerVia", {
      is:(value) => value === "api", // Condition: when 'customerVia' is 'excel'
      then: () =>  Yup.string().required("Required"), // Then make 'acType' required
      otherwise: () => Yup.string(), // Otherwise, 'acType' is not required
    }),
    template: Yup.string().required("Required"),
    file: Yup.mixed().when("customerVia", {
      is: (value) => value === "excel",
      then: () =>
        Yup.mixed()
          .required("Required")
          .test(
            "fileType",
            "Only Excel files are allowed",
            (value) =>
              value &&
              [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
              ].includes(value.type)
          ),
      otherwise: () => Yup.mixed().notRequired(),
    }),
  });

  const fetchTemplates = async () => {
    try {
      const response = await getTemplates(user.customer);
      console.log("🚀 ~ fetchTemplates ~ response", response.data.data);
      setTemplates(response.data.data);
    } catch (error) {
      toast.error("error");
    }
  };
  useEffect(() => {
    if (user) {
      fetchTemplates();
    }
  }, [user]);
  const showModal = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomModal
          title="Send Broadcast"
          body="Are you sure you want to send broadcast message ?"
          buttons={[
            {
              color: "danger",
              onClick: onClose,
              text: "Cancel",
            },
            {
              color: "info",
              onClick: () => {
                handleBroadcastMessage(data);
                onClose();
              },
              text: "Send",
            },
          ]}
        />
      ),
      overlayClassName: "modal-overlay",
    });
  };
  const handleBroadcastMessage = async (data) => {
    try {
      console.log("Data - - - - - ",data);
      let formData;
      if (data.file) {
        formData = new FormData();
        formData.append("customerVia", data.customerVia);
        formData.append("acType", data.acType);
        formData.append("template", data.template);
        formData.append("file", data.file);
      }
      console.log("🚀 ~ handleBroadcastMessage ~ formData:", formData);
      const response = await sendBroadcastMessage(
        user.customer,
        data.file ? formData : data
      );
      toast.success("Broadcast Message sended");
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <p className="mb-5">Send Broadcast Message</p>


              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={showModal}
              >
                {({ setFieldValue, values }) => (
                  <FormikForm>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Select Customers via</label>
                          <Field
                            as="select"
                            name="customerVia"
                            id="customerVia"
                            className="form-control"
                            onChange={(event) => {
                              setFieldValue("customerVia", event.target.value);
                              if (event.target.value === "api") {
                                setdisableAcType(false);
                              } else {
                                setdisableAcType(true);
                              }
                            }}
                          >
                            <option defaultChecked value={"api"}>
                              Using API (Default)
                            </option>
                            <option value={"excel"}>Using Excel Sheet</option>
                          </Field>
                          <ErrorMessage
                            component={"span"}
                            name="customerVia"
                            className="input-error"
                          />
                        </FormGroup>
                      </Col>
                      {values.customerVia === "excel" && (
                        <Col md="6">
                          <FormGroup>
                            <label>Choose excel sheet</label>
                            {/* <Field name="file"> */}
                            <input
                              type="file"
                              id="file"
                              name="file"
                              className="form-control"
                              onChange={(event) =>
                                setFieldValue(
                                  "file",
                                  event.currentTarget.files[0]
                                )
                              }
                            />
                            {/* </Field> */}
                            <ErrorMessage
                              component={"span"}
                              name="file"
                              className="input-error"
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md="6" >
                        <FormGroup>
                          <label>Choose A/C Type</label>
                          <Field
                            as="select"
                            name="acType"
                            id="acType"
                            className="form-control"
                            disabled={disableAcType}
                          >
                            <option defaultChecked></option>
                            <option value={'Savings A/C'}>Savings A/C</option>
                            <option value={'Current A/C'}>Current A/C</option>
                          </Field>
                          <ErrorMessage
                            component={"span"}
                            name="acType"
                            className="input-error"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Choose Template</label>
                          <Field
                            as="select"
                            id="template"
                            name="template"
                            className="form-control"
                            //onChange={(event) => setFieldValue("template", event.target.value)}
                          >
                            <option defaultChecked></option>
                            {templates.map((template, index) => {
                              console.log("🚀 ~ template", template);
                             return  <option key={index} value={template.template_Id}>  {template.name} </option>
                             
                            })}
                          </Field>
                          <ErrorMessage
                            component={"span"}
                            name="template"
                            className="input-error"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3 justify-content-end">
                      <Col>
                        <Button className="btn-info w-100" type="submit">
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Broadcast;
