import { getCustomer } from "api/services/customerService";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
  Progress,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [customersClone, setCustomerClone] = useState([]);
  const [filterType, setFilterType] = useState("All");
  const fetchCustomers = async () => {
    try {
      const response = await getCustomer();
      setCustomers(response.data);
      setCustomerClone(response.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    fetchCustomers();
  }, []);
  const handlefilterCustomers = (status) => {
    setCustomerClone(() => {
      return customers.filter((req) => {
        return req.isDeleted == status;
      });
    });
  };
  return (
    <div className="content">
      <Row>
        <Col>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <CardTitle tag="h4">Customers / {filterType}</CardTitle>
              {/* <Link to="create">
                <Button
                  className="btn-fill d-flex align-items-center"
                  color=""
                  type="button"
                >
                  <i className="fa fa-plus mr-2"></i>
                  Add New Customer
                </Button>
              </Link> */}
              <div className="d-flex align-items-center">
                <i
                  className="fa-solid fa-arrows-rotate mr-2"
                  role="button"
                  onClick={() => {
                    setFilterType("All");
                    setCustomerClone(customers);
                  }}
                />
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      onClick={(e) => {
                        setFilterType("Active");
                        handlefilterCustomers(false);
                      }}
                    >
                      Active
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        setFilterType("In Active");
                        handlefilterCustomers(true);
                      }}
                    >
                      In Active
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <CardBody>
              <Table className="tablesorter">
                <thead className="text-primary">
                  <tr>
                    <th>No.</th>
                    <th style={{ width: "200px" }}>Name</th>
                    <th>Email</th>
                    <th>Contact Person's Number</th>
                    <th style={{ width: "150px" }}>
                      Whatsapp Banking Usage(%)
                    </th>
                    <th style={{ width: "150px" }}>Broadcast Usage(%)</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customersClone.map((customer, index) => {
                    const whatsappBankingUsage =
                      (customer.whatsappBanking / customer.messageLimit) * 100;
                    const broadcastMessageUsage =
                      (customer.broadcatMessage /
                        customer.broadcatMessageLimit) *
                      100;
                    return (
                      <tr key={customer._id}>
                        <td>{index + 1}</td>
                        <td>{customer.name}</td>
                        <td>{customer.email}</td>
                        <td>{customer.contactPersonNumber}</td>
                        <td>
                          <Progress value={whatsappBankingUsage} />
                        </td>
                        <td>
                          <Progress value={broadcastMessageUsage} />
                        </td>
                        <td className="text-center">
                          <Link to={`actions/${customer._id}`}>
                            <i className="fa-solid fa-angles-right"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Customers;
