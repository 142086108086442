import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams, useNavigate } from "react-router-dom";
import { emailVerification } from "api/services/userService";

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const verifyEmail = async (token) => {
    try {
      setLoading(true);
      //Call the API to verify the email address
      const response = await emailVerification({ token });
      console.log("response ---- --------", response);

      if (response.status === 200) {
        //Handle success
        toast.success(response.data.message);
        setVerified(true);
        setResponseText(response.data.message);
        setLoading(false)
        setTimeout(() => {
          navigate('/signin')
        }, 4000);
      } else {
        setError(true);
        console.log();

        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      //Handle error
      console.log(error);
      setError(true);
      toast.error("Email verification failed");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail(token);
    }
  }, [token]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card mt-5">
            <div className="card-header">
              <h3 className="mb-1">Email Verification</h3>
            </div>
            <div className="card-body">
              {loading && (
                <>
                  Please wait while we verify your email address.
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ml-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                </>
              )}
              {verified && (
                <>
                  <p>{responseText}</p>
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </button>
                </>
              )}
              {error && (
                <>
                  <p>Email verification failed. Please try again.</p>
                  <button className="btn btn-primary">
                    Resend Verification Email
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
