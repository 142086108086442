import { API_ENDPOINTS } from "../apiEndpoints";
import axiosInstance from "api/axiosConfig";

export const getAllRequests = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.GET_ALL_REQUESTS());
    return response;
  } catch (error) {
    throw error;
  }
};
