import { getCustomer } from "api/services/customerService";
import { useAuth } from "contexts/authContext";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  CardFooter,
  CardText,
  // FormGroup,
  // Form,
  // Input,
  Row,
  Col,
} from "reactstrap";

function UserProfile() {
  const { user } = useAuth();
  const [customer, setCustomer] = useState({});
  const fetchCustomerDetails = async () => {
    try {
      const response = await getCustomer(user.customer);
      setCustomer(response.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (user) {
      if (user.role.name === "user") {
        fetchCustomerDetails();
      }
    }
  }, [user]);
  return (
    <>
      <div className="content">
        <Row>
          {/* {user.role.name === "user" && (
            <Col md="8">
              <Card>
                <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Bank</label>
                          <Input value={customer.name} type="text" />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>User Name</label>
                          <Input type="text" value={customer.name} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Email</label>
                          <Input value={customer.email} type="email" />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Wh.app Number</label>
                          <Input value={customer.whatsAppNo} type="text" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input value={customer.address} type="text" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Contact Person's Name</label>
                          <Input
                            value={customer.contactPersonName}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Contact Person's Number</label>
                          <Input
                            value={customer.contactPersonNumber}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                <Button className="btn-fill" color="primary" type="submit">
                  Save
                </Button>
              </CardFooter>
              </Card>
            </Col>
          )} */}
          <Col md="12">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author mb-4">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <img
                    alt="..."
                    className="avatar"
                    src={require("assets/img/default-avatar.png")}
                  />
                  {/* <h5 className="title">Hi welcome</h5> */}
                  {user?.role.name === "admin" && (
                    <>
                      <p className="description">{user.name}</p>
                    </>
                  )}
                </div>
                {user?.role.name === "user" && (
                  <>
                    <Row className="mb-2">
                      <Col>
                        <p className="title">Name</p>
                        <h5>{customer.name}</h5>
                      </Col>
                      <Col>
                        <p className="title">User Name</p>
                        <h5>{user.username}</h5>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <p className="title">Email</p>
                        <h5>{customer.email}</h5>
                      </Col>
                      <Col>
                        <p className="title">Wh.app Number</p>
                        <h5>{customer.whatsAppNo}</h5>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <p className="title">Email</p>
                        <h5>{customer.email}</h5>
                      </Col>
                      <Col>
                        <p className="title">Wh.app Number</p>
                        <h5>{customer.whatsAppNo}</h5>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <p className="title">Address</p>
                        <h5>{customer.address}</h5>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <p className="title">Contact Person's Name</p>
                        <h5>{customer.contactPersonName}</h5>
                      </Col>
                      <Col>
                        <p className="title">Contact Person's Number</p>
                        <h5>{customer.contactPersonNumber}</h5>
                      </Col>
                    </Row>
                  </>
                )}
                {/* <div className="card-description">
                  Do not be scared of the truth because we need to restart the
                  human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </div> */}
              </CardBody>
              <CardFooter>
                {/* <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
