import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { AuthProvider } from "contexts/authContext";
import routes from "routes";
import { Toaster } from "react-hot-toast";
import "./utils/facebookSDK";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <Toaster />
      <AuthProvider>
        <RouterProvider router={routes} />
      </AuthProvider>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
