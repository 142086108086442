import React, { useEffect, useState } from "react";

// reactstrap components
import { Card, CardBody, Row, Col } from "reactstrap";

import { getDashboardData } from "api/services/dashboardService";
import { useAuth } from "contexts/authContext";
import DashboardCharts from "components/DashboardCharts/DashboardCharts";
import toast from "react-hot-toast";

function Dashboard(props) {
  const { user } = useAuth();
  const [userChartData, setUserChartData] = useState({
    barChartData: [],
    gaugeChartData: {
      whatsappBankingGaugeData: 0,
      whatsappBroadcastGaugeData: 0,
    },
  });
  const [adminChartData, setAdminChartData] = useState({
    barChartData: [],
    pieChartData: {},
  });
  const [userCardtData, setUserCardData] = useState({
    whatsappBankingMessageCount: 0,
    whatsappBankingBalance: 0,
    whatsappBankingMessageLimit: 0,
    whatsappBroadcastMessageCount: 0,
    whatsappBroadcastBalance: 0,
    whatsappBroadcastMessageLimit: 0,
  });
  const [adminCardtData, setAdminCardData] = useState({
    totalCustomers: 0,
    totalWhatsappBankingMessageCount: 0,
    totalWhatsappBroadcastMessageCount: 0,
    alertCustomers: 0,
  });
  const fetchDashboardData = async () => {
    try {
      const response = await getDashboardData(
        user?.role.name,
        user?.role.name === "user" && user?.customer
      );
      if (user?.role.name == "user") {
        setUserChartData((prev) => {
          return {
            ...prev,
            barChartData: response.data.data.barChart,
            gaugeChartData: {
              whatsappBankingGaugeData:
                response.data.data.whatsappBankingGaugeData,
              whatsappBroadcastGaugeData:
                response.data.data.whatsappBroadcastGaugeData,
            },
          };
        });

        setUserCardData((prev) => {
          return {
            ...prev,
            ...response.data.data,
            // whatsappBankingMessageCount:
            //   response.data.data.whatsappBankingMessageCount,
            // whatsappBroadcastMessageCount:
            //   response.data.data.whatsappBroadcastMessageCount,
            // whatsappBroadcastMessageLimit:
            //   response.data.data.whatsappBroadcastMessageLimit,
            // whatsappBroadcastBalance:
            //   response.data.data.whatsappBroadcastMessageLimit -
            //   response.data.data.whatsappBroadcastMessageCount,
            // whatsappBankingMessageLimit:
            //   response.data.data.whatsappBankingMessageLimit,
            // whatsappBankingBalance:
            //   response.data.data.whatsappBankingMessageLimit -
            //   response.data.data.whatsappBankingMessageCount,
          };
        });
      } else {
        setAdminCardData((prev) => {
          return {
            ...prev,
            totalWhatsappBankingMessageCount:
              response.data.data.whatsappBankingMessageCount,
            totalWhatsappBroadcastMessageCount:
              response.data.data.whatsappBroadcastMessageCount,
            totalCustomers: response.data.data.allCustomers?.length,
            alerCustomers: response.data.data.pieChart.red,
          };
        });
        setAdminChartData((prev) => {
          return {
            ...prev,
            barChartData: response.data.data?.barChart,
            pieChartData: response.data.data?.pieChart,
          };
        });
      }
    } catch (error) {
      toast.error("error");
    }
  };
  useEffect(() => {
    if (user) {
      fetchDashboardData();
    }
  }, [user]);
  return (
    <>
      <div className="content">
        {user?.role.name === "admin" ? (
          <Row>
            <Col lg="3">
              <Card className="card-chart">
                <CardBody className="p-4 d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                    className="bg-info d-flex align-items-center justify-content-center"
                  >
                    <i className="fa-regular fa-user fa-xl text-white" />
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h5 className="card-category">Total Customers</h5>
                    <p className="h2 m-0"> {adminCardtData.totalCustomers}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card className="card-chart">
                <CardBody className="p-4 d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                    className="bg-success d-flex align-items-center justify-content-center"
                  >
                    <i className="fa-brands fa-whatsapp fa-xl text-white" />
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h5 className="card-category">Whatsapp Banking</h5>
                    <p className="h2 m-0">
                      {adminCardtData.totalWhatsappBankingMessageCount}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card className="card-chart">
                <CardBody className="p-4 d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                    className="bg-primary d-flex align-items-center justify-content-center"
                  >
                    <i class="fa-solid fa-bullhorn fa-xl text-white"></i>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h5 className="card-category">Total Broadcasts</h5>
                    <p className="h2 m-0">
                      {adminCardtData.totalWhatsappBroadcastMessageCount}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card className="card-chart">
                <CardBody className="p-4 d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                    className="bg-danger d-flex align-items-center justify-content-center"
                  >
                    <i className="fa-solid fa-circle-exclamation fa-xl text-white" />
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h5 className="card-category">Alert Customers</h5>
                    <p className="h2 m-0"> {adminCardtData.alertCustomers}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg="6">
              <Card className="card-chart p-3">
                <Row style={{ rowGap: "25px" }}>
                  <Col lg="12">
                    <Card className="card-chart custom-card-chart">
                      <CardBody className="p-4 d-flex align-items-center justify-content-between">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                          }}
                          className="bg-success d-flex align-items-center justify-content-center"
                        >
                          <i className="fa-brands fa-whatsapp fa-xl text-white" />
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="card-category">
                            Whatsapp Banking Subscription
                          </h5>
                          <p className="h2 m-0">
                            {userCardtData.whatsappBankingMessageLimit}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card
                      className="card-chart custom-card-chart"
                      style={{ background: "#2e3048" }}
                    >
                      <CardBody className="p-4 d-flex align-items-start justify-content-between">
                        <div>
                          <i className="fa-solid fa-paper-plane fa-xl text-danger" />
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="card-category">Total Message Sent</h5>
                          <p className="h2 m-0">
                            {userCardtData.whatsappBankingMessageCount}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card
                      className="card-chart custom-card-chart"
                      style={{ background: "#2e3048" }}
                    >
                      <CardBody className="p-4 d-flex align-items-start justify-content-between">
                        <div>
                          <i className="fa-solid fa-cubes-stacked fa-xl text-warning" />
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="card-category">Balance</h5>
                          <p className="h2 m-0">
                            {userCardtData.whatsappBankingBalance}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg="6">
              <Card className="card-chart p-3">
                <Row style={{ rowGap: "25px" }}>
                  <Col lg="12">
                    <Card className="card-chart custom-card-chart">
                      <CardBody className="p-4 d-flex align-items-center justify-content-between">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                          }}
                          className="bg-info d-flex align-items-center justify-content-center"
                        >
                          <i class="fa-solid fa-bullhorn fa-xl text-white"></i>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="card-category">
                            Broadcasting Subscription
                          </h5>
                          <p className="h2 m-0">
                            {userCardtData.whatsappBroadcastMessageLimit}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card
                      className="card-chart custom-card-chart"
                      style={{ background: "#2e3048" }}
                    >
                      <CardBody className="p-4 d-flex align-items-start justify-content-between">
                        <div>
                          <i className="fa-solid fa-paper-plane fa-xl text-danger" />
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="card-category">Total Message Sent</h5>
                          <p className="h2 m-0">
                            {userCardtData.whatsappBroadcastMessageCount}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card
                      className="card-chart custom-card-chart"
                      style={{ background: "#2e3048" }}
                    >
                      <CardBody className="p-4 d-flex align-items-start justify-content-between">
                        <div>
                          <i className="fa-solid fa-cubes-stacked fa-xl text-warning" />
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="card-category">Balance</h5>
                          <p className="h2 m-0">
                            {userCardtData.whatsappBroadcastBalance}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <DashboardCharts
          chartData={user?.role.name == "user" ? userChartData : adminChartData}
        />
      </div>
    </>
  );
}

export default Dashboard;
