import { API_ENDPOINTS } from "../apiEndpoints";
import axiosInstance from "api/axiosConfig";

export const updateUser = async (id, data) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.UPDATE_USER(id), {
      ...data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const signUp = async (data) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.SIGNUP(), {
      ...data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const emailVerification = async (data) => {
  try {
    console.log(data);

    const response = await axiosInstance.post(
      API_ENDPOINTS.EMAIL_VERIFICATION(),
      data
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
