import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Container,
  CardImg,
} from "reactstrap";

import { ReactComponent as Logo } from "logo.svg";
import { useAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import login_bg from "assets/img/login_bg.jpg";

const Signin = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await login(data);
      toast.success("Logged in successfully");
      navigate("/dashboard");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  const initialValues = {
    userName: "",
    password: "",
  };
  const validationSchema = Yup.object({
    userName: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
  });

  // "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"

  return (
    <Container className="vh-100 d-flex align-items-center">
      <Card className="card-custom">
        <Row>
          <Col md="5" className="d-none d-md-block">
            <img
              src={login_bg}
              alt="login form"
              className="rounded-start h-100"
            />
          </Col>
          <Col md="7">
            <CardBody className="d-flex flex-column justify-content-center align-items-center h-100 px-4">
              <div style={{ width: "450px", maxWidth: "100%" }}>
                <div
                  className="d-flex flex-column mt-2"
                  style={{ gap: "15px" }}
                >
                  <Logo />
                  <h1 className="mb-0 text-white">Perfect Connects</h1>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <FormikForm>
                    <h5 className="my-4 pb-3">Sign into your account</h5>
                    <FormGroup>
                      <label>Email / Username</label>
                      <Field
                        placeholder="Username"
                        type="text"
                        name="userName"
                        id="userName"
                        className="form-control"
                      />
                      <ErrorMessage
                        component={"span"}
                        name="userName"
                        className="input-error"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Password</label>
                      <Field
                        placeholder="Password"
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                      />
                      <ErrorMessage
                        component={"span"}
                        name="password"
                        className="input-error"
                      />
                    </FormGroup>
                    <Button
                      className="btn-fill w-100"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Login
                    </Button>
                  </FormikForm>
                </Formik>
                <div className="d-flex flex-row justify-content-start">
                  <a
                    href="/legal/terms_of_service_and_privacy_policy.html"
                    className="small text-muted me-1"
                  >
                    Privacy policy and Terms of Service.
                  </a>
                </div>
                <div className="text-center mt-5">
                
                    <h5>
                     New user? <a href="/signup"><b>Signup</b></a> here
                    </h5>
                    
                  <a href="https://perfectconnects.in">
                    <h5>
                      Go to <b>Home</b> page
                    </h5>
                  </a>
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Signin;
