import { redirect } from "react-router-dom";

export function checkAuthLoader() {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  if (!(token && user)) {
    return redirect("/signin");
  }
  return true;
}
