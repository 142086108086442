import { API_ENDPOINTS } from "../apiEndpoints";
import axiosInstance from "api/axiosConfig";

export const getCustomer = async (id) => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.GET_CUSTOMER(id));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRequestsByCustomerId = async (id) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.GET_REQUESTS_BY_CUSTOMER_ID(id)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.UPDATE_CUSTOMER(id),
      { ...data }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTemplates = async (id) => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.GET_TEMPLATES(id));
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendBroadcastMessage = async (id, data) => {
  try {
    const config = {
      headers:
        data instanceof FormData
          ? { "Content-Type": "multipart/form-data" }
          : { "Content-Type": "application/json" },
    };
    const response = await axiosInstance.post(
      API_ENDPOINTS.SEND_BROADCAST_MESSAGE(id),
      data,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};
