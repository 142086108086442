/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="">About Us</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="">Contact Us</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="">Help Center</NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()}{" "}
          <a href="" target="_blank">
            Perfectconnects.in
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
