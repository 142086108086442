import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Typography,
  StepButton,
  TextField,
} from "@mui/material";
// import { FacebookProvider ,LoginButton} from "react-facebook";
import "../assets/css/createSender.css";
import FbLogin from "./FbLogin";


const CreateSender = () => {
  const steps = ["Select a Phone number", "Link with whatsapp business"];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const hideNext = false;

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
   
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleError = (error)=>{
    console.log("error",error)
  }

  const handleSuccess = (response)=>{
    console.log(response);
    
    console.log("success")
  }

  return (
    <div className="content">
      <h1>Create Sender</h1>
      <Box sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <div>
          {allStepsCompleted() ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography> */}
              {activeStep === 0 && (
                <div className="card my-2  ">
                  <div className="card-body p-4">
                    <h5 className="card-title font-weight-normal">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>{" "}
                      Before Selecting Number
                    </h5>
                    <p className="card-text">
                      If your phone number is already connected to the WhatsApp
                      consumer or small business application, please disconnect
                      it prior to continuing below. If it is already connected
                      to the WhatsApp Business API via a different provider,
                      please open a support ticket to migrate the number
                      instead.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    {/* x */}
                    <form>
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Enter Phone number
                        </label>
                        <input
                          type="text"
                          class="crt-sender form-control"
                          id="inputPhoneNumber"
                          aria-describedby="emailHelp"
                          placeholder=""
                          required
                        />
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="card my-2  ">
                  <div className="card-body p-4">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title font-weight-normal">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>{" "}
                          Keep the window open
                        </h5>

                        <p className="card-text">
                          Please keep this window open while you create or
                          select your WhatsApp Business Account.
                        </p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5>Link your whatsapp account</h5>
                      </div>
                      <div className="card-body">
                        <p>
                          Link your WhatsApp Business Account. You’ll be guided
                          through linking your Meta and WhatsApp Business
                          Accounts. If you don’t have either account, you’ll be
                          able to create one.
                        </p>
                        <div className="d-flex align-items-center">
                          {/* <Button variant="contained" className="mt-2">
                            Continue with facebook
                          </Button> */}

    {/* <FacebookProvider appId="920102326252805">
      <LoginButton
        scope="email"
        onError={handleError}
        onSuccess={handleSuccess}
      >
        Login via Facebook
      </LoginButton>
    </FacebookProvider> */}

<FbLogin />

                          <span className="ml-3">
                            You will be directed to Facebook to set up WhatsApp
                            in a popup window.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                variant="contained"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                variant="contained"
                  disabled={isLastStep()}
                  onClick={handleNext}
                  sx={{ mr: 1 }}
                >
                  Next
                </Button>
                {/* {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block" }}
                    >
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1
                        ? "Finish"
                        : "Complete Step"}
                    </Button>
                  ))} */}
              </Box>
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

export default CreateSender;
