import { getAllRequests } from "api/services/adminService";
import { getRequestsByCustomerId } from "api/services/customerService";
import { useAuth } from "contexts/authContext";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

const Requests = () => {
  const { user } = useAuth();
  const [requests, setRequests] = useState([]);
  const [requestsClone, setRequestsClone] = useState([]);
  const [filterType, setFilterType] = useState("All");
  const fetchRequests = async () => {
    try {
      const response =
        user.role.name === "user"
          ? await getRequestsByCustomerId(user.customer)
          : await getAllRequests();
      setRequests(response.data.data);
      setRequestsClone(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handlefilterRequests = (status) => {
    setRequestsClone(() => {
      return requests.filter((req) => {
        return req.status == status;
      });
    });
  };
  useEffect(() => {
    if (user) {
      fetchRequests();
    }
  }, [user]);
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <CardTitle tag="h4">Requests / {filterType}</CardTitle>
              <div className="d-flex align-items-center">
                <i
                  className="fa-solid fa-arrows-rotate mr-2"
                  role="button"
                  onClick={() => {
                    setFilterType("All");
                    setRequestsClone(requests);
                  }}
                />
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        setFilterType("Approved");
                        handlefilterRequests("approved");
                      }}
                    >
                      Approved
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        setFilterType("Returned");
                        handlefilterRequests("Returned");
                      }}
                    >
                      Returned
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        setFilterType("Rejected");
                        handlefilterRequests("Rejected");
                      }}
                    >
                      Rejected
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <CardBody>
              {requestsClone.length === 0 && (
                <div className="text-center">
                  <p>No data available</p>
                </div>
              )}
              {requestsClone.length > 0 && (
                <>
                  <Table className="tablesorter">
                    <thead className="text-primary">
                      {user?.role.name == "admin" ? (
                        <tr>
                          <th>No.</th>
                          <th>From</th>
                          <th>Branch</th>
                          <th>Contact Person</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>No.</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {user?.role?.name == "admin"
                        ? requestsClone?.map((request, index) => {
                            var requestDate = new Date(
                              request.createdAt
                            ).toLocaleString();
                            return (
                              <tr key={request._id}>
                                <td>{index + 1}</td>
                                <td>{request.customer?.name}</td>
                                <td>
                                  {request.customer?.branchType == "Main"
                                    ? "Main"
                                    : request.customer?.branch}
                                </td>
                                <td>{request.customer?.contactPersonName}</td>
                                <td>{request.status}</td>
                                <td>{requestDate}</td>
                                <td className="text-center">
                                  <Link to={`/request/${request._id}`}>
                                    <i className="fa-solid fa-angles-right"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        : requestsClone?.map((request, index) => {
                            var requestDate = new Date(
                              request.createdAt
                            ).toLocaleString();
                            return (
                              <tr key={request._id}>
                                <td>{index + 1}</td>
                                <td>{request.type}</td>
                                <td>{request.status}</td>
                                <td>{requestDate}</td>
                                <td className="text-center">
                                  <Link to={`/request/${request._id}`}>
                                    <i className="fa-solid fa-angles-right"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </Table>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Requests;
