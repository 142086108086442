import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

const FbLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const loadFbSdk = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "920102326252805",
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });
        window.FB.AppEvents.logPageView();
        checkLoginState();
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFbSdk();
  }, []);

  const checkLoginState = () => {
    if (window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          setIsLoggedIn(true);
          setAccessToken(response.authResponse.accessToken);
        } else {
          setIsLoggedIn(false);
          setAccessToken(null);
        }
      });
    }
  };

  const launchWhatsAppSignup = () => {
    if (window.FB) {
      window.FB.login(
        function (response) {
          console.log("Response from FB", response);

          if (response.authResponse) {
            setIsLoggedIn(true);
            setAccessToken(response.authResponse.accessToken);
          } else {
            setIsLoggedIn(false);
            setAccessToken(null);
          }
        },
        {
          config_id: "865976752073470", // configuration ID obtained in the previous step goes here
          response_type: "code", // must be set to 'code' for System User access token
          override_default_response_type: true,
          extras: {
            featureType: "only_waba_sharing",
            setup: {
              whatsapp_number: "+1 (555) 603-8315", // WhatsApp number to be used for the business
              whatsapp_namespace: "Perfect cables and Connectivity Solutions", // WhatsApp namespace for the business
              ref: "test", // Optional. Any string value to be returned in the callback
              data: "test", // Optional. Any string value to be returned in the callback

              // Prefilled data can go here
            },
          },
        }
      );
    }
  };

  return (
    <div>
      <Button onClick={launchWhatsAppSignup}>
        {isLoggedIn ? "Logout" : "Login with Facebook"}
      </Button>
    </div>
  );
};

export default FbLogin;
